* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  color: inherit;
}

body,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
}

input,
button {
  border: none;
}

body {
  background: #ffffff;
}
a {
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 90vh;
  /* height: 100vh; */
}
main {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 15px;
}
