.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

label {
    font-size: 18px;
    margin-bottom: 10px;
}

.input_prop {
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
    margin-left: 15px;
    outline: none;
}

.result {
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
}
.language-switch {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.language-switch span {
    font-size: 1.2rem;
    margin-right: 10px;
}

.language-switch label {
    font-size: 1.2rem;
    margin-right: 10px;
}

.language-switch input[type="radio"] {
    margin-right: 5px;
}