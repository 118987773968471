.menu_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 25px;
  margin-top: 0px;
  height: 100%;
  /* background-color: #2c3e50; */
  width: 450px;
  position: relative;
  box-shadow: 1px 1px 2px 1px grey;
  padding-top: 10px;
  transition: margin-left 1s;
/*   background-color: #f2f2f2; */
}
.menu_container.close {
  margin-left: -350px;
  transition: margin-left 1s;
}
.btn_conteiner {
  width: 100%;
  height: 50px;
}
.title {
  margin-left: 8px;
  margin-top: 5px;
  /* color: white; */
  color: #282c34;
  font-size: 3rem;
  cursor: pointer;
  width: 200px;
  font-weight: 500;
}
.menu_button_position {
  position: absolute;
  top: 10px;
  right: 0px;
  width: 70px;
  height: 50px;
}
.version {
  position: absolute;
  bottom: 10px;
  left: 25px;
  font-size: 14px;
  color: #999999;
  margin: 10px 0;
}
