.container {
  width: 99%;
  height: 100%;
  padding-left: 20px;
}
.income_form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.input_box {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;
}
.input_view {
  margin-right: 15px;
  height: 40px;
  border-bottom: 1px solid #282c34;
  font-size: 20px;
  outline: none;
  color: #282c34;
  /*   box-shadow: 1px 1px 2px 1px grey;
  border-radius: 5px; */
  margin-bottom: 0px;
}
.input_title {
  font-size: 13px;
  margin-bottom: 5px;
  color: #282c34;
  font-weight: 500;
  margin-left: 10px;
}
.add_button {
  margin-top: 30px;
  height: 40px;
  border-bottom: 1px solid #282c34;
  font-size: 20px;
  outline: none;
  color: white;
  box-shadow: 1px 1px 2px 1px grey;
  border-radius: 5px;
  background-color: #04aa6d;
  cursor: pointer;
}
.del_button {
  margin-top: 30px;
  height: 40px;
  border-bottom: 1px solid #282c34;
  font-size: 20px;
  outline: none;
  color: white;
  box-shadow: 1px 1px 2px 1px grey;
  border-radius: 5px;
  background-color: red;
  cursor: pointer;
  margin-left: 10px;
}
.add_button:active {
  box-shadow: 1px 2px 3px 1px black;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
