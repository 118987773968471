.App-logo {
  margin-left: 100px;
  margin-right: 15px;
  height: 6vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    //использовать для кручения
    animation: App-logo-spin infinite 0s linear;
  }
}

.App-header {
  min-width: 100%;
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
ul {
  margin-right: 100px;
}
li {
  list-style-type: none;
  display: inline;
  font-size: 20px;
  margin-left: 30px;
}
li:hover {
  cursor: pointer;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.nav_item {
  color: white;
  text-decoration: none;
}
.logo_conteiner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.login_button {
  border: solid 1px white;
  border-radius: 5px;
  background-color: #282c34;
  margin-right: 40px;
  height: 25px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.user_name {
  font-size: 1.5rem;
  margin-right: 10px;
  color: white;
  /* color: #fcad03; */
  opacity: 0.7;
}
.img_log {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
