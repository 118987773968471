.currencies_wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
    justify-content: center;
}
.converter_wrapper {
  width: 60%;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
}
.converter_select {
  height: 100%;
  border-radius: 5px;
  width: 50%;
}
.converter_select:hover {
  cursor: pointer;
}
.input_view {
  height: 100%;
  border: 1px solid #282c34;
  width: 40%;
  font-size: 15px;
  outline: none;
  margin-left: 15px;

  border-radius: 5px;
}
.converter_block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  margin-top: 15px;
}
.title_convertetion{
  font-size: 20px;
  margin-right: 20px;
}
.input_date{
    margin-right: 15px;
      height: 40px;
      border-bottom: 1px solid #282c34;
      font-size: 20px;
      outline: none;
      color: #282c34;
      /*   box-shadow: 1px 1px 2px 1px grey;
      border-radius: 5px; */
      margin-bottom: 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
